import useQueryWithTracing from '@aurora/shared-client/components/useQueryWithTracing';
import { stripCustomComponentIdPrefix } from '@aurora/shared-client/helpers/components/CustomComponentsHelper';
import type { WidgetCategory } from '@aurora/shared-client/types/enums';
import type { ComponentPageScope } from '@aurora/shared-generated/types/graphql-schema-types';
import type { ComponentResolveServerFragment } from '@aurora/shared-generated/types/graphql-types';
import { getLog } from '@aurora/shared-utils/log';
import { getWidgetCategoryFromComponentTemplate } from '../../helpers/quilt/WidgetHelper';
import type { ComponentsQuery, ComponentsQueryVariables } from '../../types/graphql-types';
import type { WidgetInfo } from '../common/Widget/WidgetRegistry';
import { widgetIconByCategoryMap } from '../common/Widget/WidgetRegistry';
import { widgetCategoryVariablesMap } from '../pageeditor/PageEditorWidgetList/PageEditorWidgetList';
import getComponents from './Components.query.graphql';

const log = getLog(module);

export function useFilteredAllowedCustomWidgetsForWidgetCategory(
  pageScope: ComponentPageScope,
  widgetCategory: WidgetCategory
): { loading: boolean; data: Record<string, WidgetInfo> } {
  /**
   * Queries for Custom Components applicable for provided template.
   */
  const {
    data: customComponentData,
    loading: customComponentLoading,
    error
  } = useQueryWithTracing<ComponentsQuery, ComponentsQueryVariables>(module, getComponents, {
    variables: {
      pageScope,
      ...widgetCategoryVariablesMap[widgetCategory]
    }
  });

  if (customComponentLoading) {
    return { loading: true, data: {} };
  }

  if (error) {
    log.error(`Unable to fetch components: ${error}`);
  }

  const allowedWidgets: Record<string, WidgetInfo> = {};
  customComponentData.components.forEach(allowedComponent => {
    const { id: allowedComponentId } = allowedComponent;
    allowedWidgets[allowedComponentId] = {
      category: widgetCategory,
      icon: widgetIconByCategoryMap[widgetCategory],
      title: stripCustomComponentIdPrefix(allowedComponentId).replaceAll('_', ' '),
      description: allowedComponent.properties.config?.description
    };
  });

  return { loading: false, data: allowedWidgets };
}

export function useFilteredAllowedCustomWidgets(
  pageScope: ComponentPageScope,
  component: ComponentResolveServerFragment
): {
  loading: boolean;
  data: Record<string, WidgetInfo>;
} {
  const widgetCategory = getWidgetCategoryFromComponentTemplate(component);

  /**
   * Queries for Custom Components applicable for provided template.
   */
  const {
    data: customComponentData,
    loading: customComponentLoading,
    error
  } = useQueryWithTracing<ComponentsQuery, ComponentsQueryVariables>(module, getComponents, {
    variables: {
      pageScope,
      ...widgetCategoryVariablesMap[widgetCategory]
    },
    skip: !component
  });

  if (customComponentLoading || !component) {
    return { loading: true, data: {} };
  }

  if (error) {
    log.error(`Unable to fetch components: ${error}`);
  }

  const allowedWidgets: Record<string, WidgetInfo> = {};
  customComponentData.components.forEach(allowedComponent => {
    const { id: allowedComponentId } = allowedComponent;
    allowedWidgets[allowedComponentId] = {
      category: widgetCategory,
      icon: widgetIconByCategoryMap[widgetCategory],
      title: stripCustomComponentIdPrefix(allowedComponentId).replaceAll('_', ' '),
      description: allowedComponent.properties.config?.description
    };
  });

  return { loading: false, data: allowedWidgets };
}
