import type { FeaturedWidgetInstance } from '@aurora/shared-client/components/context/FeaturedWidgetContext/FeaturedWidgetContext';
import PageContext from '@aurora/shared-client/components/context/PageContext/PageContext';
import type { ProvisionalTextData } from '@aurora/shared-client/components/context/ProvisionalTextContext/ProvisionalTextContext';
import type { DetailedSelectFieldSpec } from '@aurora/shared-client/components/form/DetailedSelectField/DetailedSelectField';
import { FormFieldVariant } from '@aurora/shared-client/components/form/enums';
import type { FormFieldType, PossibleValue } from '@aurora/shared-client/components/form/types';
import { getComponentPageScopeForPageId } from '@aurora/shared-client/helpers/components/CustomComponentsHelper';
import type IconTypes from '@aurora/shared-client/public/static/graphics/processed/enums';
import type { ComponentPageScope } from '@aurora/shared-generated/types/graphql-schema-types';
import { SectionEditLevel } from '@aurora/shared-generated/types/graphql-schema-types';
import type { QuiltFragment } from '@aurora/shared-generated/types/graphql-types';
import { EndUserComponent } from '@aurora/shared-types/pages/enums';
import type { I18n } from '@aurora/shared-types/texts';
import { isObjectEmpty } from '@aurora/shared-utils/helpers/objects/ObjectHelper';
import { getLog } from '@aurora/shared-utils/log';
import React, { useContext, useState } from 'react';
import { useClassNameMapper } from 'react-bootstrap';
import type { FieldValues } from 'react-hook-form/dist/types';
import type { FieldPath } from 'react-hook-form/dist/types/path';
import endUserComponentRegistry from '../../../features/endUserComponentRegistry';
import type { SectionWidgetData } from '../../../helpers/quilt/PageEditorHelper';
import {
  isPageEditorSectionWidget,
  WidgetEditLevel
} from '../../../helpers/quilt/PageEditorHelper';
import type { ConfigurationSpec, WidgetDescriptor, WidgetProps } from '../../common/Widget/types';
import type { WidgetInfo } from '../../common/Widget/WidgetRegistry';
import { WidgetRegistry } from '../../common/Widget/WidgetRegistry';
import EditContext from '../../context/EditContext/EditContext';
import { useFilteredAllowedCustomWidgetsForWidgetCategory } from '../../customComponent/useFilteredAllowedCustomWidgets';
import useAllowedWidgets from '../../useAllowedWidgets';
import useTranslation from '../../useTranslation';
import { usePageEditorWidgetText } from '../UsePageEditorWidgetText/usePageEditorWidgetText';
import WidgetEditorForm from '../WidgetEditorForm/WidgetEditorForm';

interface Props<WidgetPropsT extends WidgetProps, WidgetDataT extends FieldValues> {
  configurationSpec: ConfigurationSpec<WidgetPropsT, WidgetDataT>;

  editLevel: WidgetEditLevel;

  editorI18n: I18n<unknown, unknown>;

  className?: string;
}

export interface WidgetDataExtension extends FieldValues {
  /**
   * Widget chooser in the edit form
   */
  widgetChooser: string;
}

const log = getLog(module);
/**
 *
 * @author Manish Shrestha
 */
const PageEditorWidgetSidebarForm = <
  WidgetPropsT extends WidgetProps,
  WidgetDataT extends FieldValues
>({
  configurationSpec,
  className,
  editLevel = WidgetEditLevel.FULL,
  editorI18n
}: Props<WidgetPropsT, WidgetDataT>): React.ReactElement => {
  const { pageId } = useContext(PageContext);
  const { quilt, onChange, selection, setSelection } = useContext(EditContext);
  const { formatMessage, loading: textLoading } = useTranslation(
    EndUserComponent.PAGE_EDITOR_WIDGET_SIDEBAR_FORM
  );

  const { title: widgetTitle, description: widgetDescription } = usePageEditorWidgetText();

  const cx = useClassNameMapper();
  const [replacementWidgetId, setReplacementWidgetId] = useState<string>(null);

  const customComponentPageScope: ComponentPageScope = getComponentPageScopeForPageId(pageId);
  const { fieldSpecs, componentId } = configurationSpec;
  const targetCategory = WidgetRegistry[componentId]?.category;

  const { data: customAllowedWidgets, loading: customComponentLoading } =
    useFilteredAllowedCustomWidgetsForWidgetCategory(customComponentPageScope, targetCategory);

  const { getAllowedWidgetsForPageAndCategory } = useAllowedWidgets();

  if (customComponentLoading || textLoading) {
    return null;
  }

  if (!isPageEditorSectionWidget(selection)) {
    log.error(
      'PageEditorWidgetSidebarForm does not support editing components that are not widgets'
    );
    return null;
  }

  const { sectionEditLevel, widgetId: currentWidgetId, props, location } = selection;

  const filteredAllowedWidgets: Record<string, WidgetInfo> = {};

  Object.entries(getAllowedWidgetsForPageAndCategory(componentId, pageId)).forEach(
    ([allowedWidgetId, allowedWidgetInfo]) => {
      filteredAllowedWidgets[allowedWidgetId] = allowedWidgetInfo;
    }
  );

  function allowWidgetReplacement(): boolean {
    /**
     * Don't allow widget replacement if widget is locked or section is locked
     */
    if (editLevel !== WidgetEditLevel.FULL || sectionEditLevel === SectionEditLevel.Locked) {
      return false;
    }
    return !isObjectEmpty(filteredAllowedWidgets);
  }

  const widgetDescriptor = endUserComponentRegistry.getWidgetDescriptor(currentWidgetId);
  const { editor: { removeModal: RemoveModal } = {} } = widgetDescriptor as WidgetDescriptor;

  function transformToPossibleValue(
    id: string,
    icon: IconTypes,
    title?: string,
    description?: string
  ): PossibleValue<
    (WidgetDataExtension & WidgetDataT)['widgetChooser'],
    WidgetDataExtension & WidgetDataT
  > {
    return {
      key: id,
      value: id as (WidgetDataExtension & WidgetDataT)['widgetChooser'],
      icon,
      label: title ?? widgetTitle(id),
      description: description ?? widgetDescription(id)
    };
  }

  const widgetChooserField: DetailedSelectFieldSpec<
    FieldPath<WidgetDataExtension & WidgetDataT>,
    WidgetDataExtension & WidgetDataT
  > = {
    name: 'widgetChooser' as FieldPath<WidgetDataExtension & WidgetDataT>,
    fieldVariant: FormFieldVariant.DETAILED_SELECT,
    values: [
      {
        category: 'default',
        categoryLabel: formatMessage('defaultCategory'),
        values: Object.entries(filteredAllowedWidgets)
          .filter(([id]) => {
            if (allowWidgetReplacement()) {
              return true;
            } else {
              return id === componentId;
            }
          })
          .map(([id, { icon, title, description }]: [string, WidgetInfo]) =>
            transformToPossibleValue(id, icon, title, description)
          )
      },
      ...(Object.keys(customAllowedWidgets).length > 0
        ? [
            {
              category: 'custom',
              categoryLabel: formatMessage('customCategory'),
              values: Object.entries(customAllowedWidgets).map(
                ([id, { icon, title, description }]: [string, WidgetInfo]) =>
                  transformToPossibleValue(id, icon, title, description)
              )
            }
          ]
        : [])
    ],
    onBeforeChange: RemoveModal
      ? value => {
          setReplacementWidgetId(value);
          return false;
        }
      : undefined,
    formGroupSpec: {
      label: false
    },
    defaultValue: componentId as (WidgetDataExtension & WidgetDataT)['widgetChooser'],
    isVisible: {
      watchFields: null,
      callback: () => {
        return !!targetCategory;
      }
    }
  };

  const adjustedFieldSpec: Array<
    FormFieldType<
      FieldPath<WidgetDataT & WidgetDataExtension>,
      WidgetDataT & WidgetDataExtension,
      FormFieldVariant
    >
  > = [
    widgetChooserField,
    ...(fieldSpecs as unknown as Array<
      FormFieldType<
        FieldPath<WidgetDataT & WidgetDataExtension>,
        WidgetDataT & WidgetDataExtension,
        FormFieldVariant
      >
    >)
  ];

  const adjustedConfigurationSpec: ConfigurationSpec<
    WidgetPropsT,
    WidgetDataT & WidgetDataExtension
  > = {
    ...(configurationSpec as unknown as ConfigurationSpec<
      WidgetPropsT,
      WidgetDataT & WidgetDataExtension
    >)
  };

  adjustedConfigurationSpec.fieldSpecs = adjustedFieldSpec;

  return (
    <>
      <WidgetEditorForm
        selection={selection}
        spec={adjustedConfigurationSpec}
        className={cx(className)}
        editorI18n={editorI18n}
        onSubmit={(
          updatedQuilt: QuiltFragment,
          textOverride?: ProvisionalTextData,
          replacementWidget?: SectionWidgetData,
          featuredWidgetData?: FeaturedWidgetInstance
        ) => {
          if (replacementWidget) {
            onChange(updatedQuilt, null, featuredWidgetData);
            setSelection(replacementWidget);
          } else {
            onChange(updatedQuilt, textOverride, featuredWidgetData);
          }
        }}
        quilt={quilt}
      />
      {replacementWidgetId && (
        <RemoveModal
          widgetProps={props}
          widgetLocation={location}
          replacementWidgetId={replacementWidgetId}
          sectionEditLevel={sectionEditLevel}
          onComplete={() => {
            setReplacementWidgetId(null);
          }}
        />
      )}
    </>
  );
};

export default PageEditorWidgetSidebarForm;
