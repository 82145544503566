import AppContext from '@aurora/shared-client/components/context/AppContext/AppContext';
import type { FeaturedWidgetInstance } from '@aurora/shared-client/components/context/FeaturedWidgetContext/FeaturedWidgetContext';
import type { ProvisionalTextData } from '@aurora/shared-client/components/context/ProvisionalTextContext/ProvisionalTextContext';
import type { SetFormFeedback } from '@aurora/shared-client/components/form/InputEditForm/InputEditForm';
import InputEditForm from '@aurora/shared-client/components/form/InputEditForm/InputEditForm';
import { useInputEditForm } from '@aurora/shared-client/components/form/InputEditForm/useInputEditForm';
import type { Quilt } from '@aurora/shared-generated/types/graphql-schema-types';
import type { QuiltFragment } from '@aurora/shared-generated/types/graphql-types';
import type { I18n } from '@aurora/shared-types/texts';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { flushSync } from 'react-dom';
import isEqual from 'react-fast-compare';
import type { FieldValues, UseFormSetError } from 'react-hook-form';
import {
  getFormSpec,
  getProvisionedText,
  getTextOverrideDataForWidget
} from '../../../helpers/pageeditor/PageEditorHelper';
import type { SectionWidgetData } from '../../../helpers/quilt/PageEditorHelper';
import { replaceSectionWidget } from '../../../helpers/quilt/SectionHelper';
import { updateWidget } from '../../../helpers/quilt/WidgetHelper';
import type { ConfigurationSpec, WidgetProps } from '../../common/Widget/types';
import EditContext from '../../context/EditContext/EditContext';
import useTranslation from '../../useTranslation';
import type { Path } from 'react-hook-form/dist/types/path';

interface Props<PropsT extends WidgetProps, FormDataT extends FieldValues> {
  /**
   * configuration spec for the widget
   */
  spec: ConfigurationSpec<PropsT, FormDataT>;

  /**
   * editor I18n
   */
  editorI18n: I18n<unknown, unknown>;

  /**
   * classname to apply to the form
   */
  className?: string;

  /**
   * @callback called when submit happens
   * @param quilt the updated quilt after submit
   * @param textOverride the text override after submit
   * @param replacementWidget defined only if the submit action replaces the widget
   */
  onSubmit: (
    quilt: Quilt,
    textOverride?: ProvisionalTextData,
    replacementWidget?: SectionWidgetData,
    featuredWidgetData?: FeaturedWidgetInstance
  ) => void;

  /**
   * the current quilt being worked on
   */
  quilt: QuiltFragment;

  /**
   * the widget selection
   */
  selection: SectionWidgetData;
}

/**
 * Form the edit the widget
 * @constructor
 * @author Manish Shrestha
 */
const WidgetEditorForm = <PropsT extends WidgetProps, FormDataT extends FieldValues>({
  spec,
  className,
  quilt,
  onSubmit,
  editorI18n,
  selection
}: Props<PropsT, FormDataT>): React.ReactElement => {
  const { contextNode } = useContext(AppContext);
  const { setSelection } = useContext(EditContext);
  const {
    textOverrideSpecs,
    instanceId,
    transformToWidgetProps,
    submitOnChange,
    formSchema,
    componentId
  } = spec;

  const defaultI18n = useTranslation(componentId);
  const ignoreProvisionedI18n = useTranslation(componentId, true);

  const formSpec = useMemo(
    () => getFormSpec(spec, editorI18n, formSchema, className),
    [spec, editorI18n, formSchema, className]
  );

  const widgetTextOverrideSpec = getTextOverrideDataForWidget(
    textOverrideSpecs,
    defaultI18n,
    ignoreProvisionedI18n,
    instanceId
  );

  const handleSubmit = useCallback(
    (
      data: FormDataT,
      actionId: string,
      event: React.FormEvent<HTMLFormElement>,
      setError: UseFormSetError<FormDataT>,
      setFeedback: SetFormFeedback,
      name?: Path<FormDataT>
    ) => {
      const { widgetChooser: widgetId } = data;
      if (widgetId && widgetId !== selection.widgetId) {
        const { updatedQuilt, nextSelection, createdFeaturedWidgetInstance } = replaceSectionWidget(
          widgetId,
          selection.location,
          selection.sectionEditLevel,
          quilt,
          contextNode.id
        );
        onSubmit(updatedQuilt, null, nextSelection, createdFeaturedWidgetInstance);
      } else {
        const adjustedData: WidgetProps = transformToWidgetProps(data) ?? {};
        adjustedData.instanceId = instanceId;

        // save "lazyLoad" option value
        adjustedData.lazyLoad = !!data?.moreOptions?.lazyLoad;

        let hasTextOverride = false;
        if (textOverrideSpecs?.some(item => item.fieldName === name)) {
          const textOverride: ProvisionalTextData = getProvisionedText(
            data,
            widgetTextOverrideSpec,
            selection.widgetId,
            instanceId,
            true,
            name
          );
          if (textOverride) {
            hasTextOverride = true;
            adjustedData.instanceId = instanceId ?? textOverride.instanceId;
            const updatedQuilt = updateWidget(quilt, selection, adjustedData);
            onSubmit(updatedQuilt, textOverride);
            flushSync(() => {
              setSelection({ ...selection, props: adjustedData });
            });
          }
        }
        if (!hasTextOverride) {
          const updatedQuilt = updateWidget(quilt, selection, adjustedData);
          onSubmit(updatedQuilt);
          setSelection({ ...selection, props: adjustedData });
        }
      }
    },
    [
      contextNode.id,
      instanceId,
      onSubmit,
      quilt,
      selection,
      setSelection,
      textOverrideSpecs,
      transformToWidgetProps,
      widgetTextOverrideSpec
    ]
  );

  const formReturn = useInputEditForm(formSpec);
  const {
    methods: {
      reset,
      formState: { isSubmitSuccessful }
    },
    defaultValues
  } = formReturn;

  const [previousValues, setPreviousValues] = useState(defaultValues);
  /**
   * React Hook form caches default values. So, when the same field is specified with a different default value, the
   * value does not change unless reset is called. This case will happen when same form field will have different default
   * values based on some other changes in form. For example: clamp lines defaults to 2 when in card mode while it defaults
   * to 1 when in inline mode for places widget. This case is only relevant when the same form component( same form id) is re-rendered
   * with the same key. For the case when form id is different, the form should be unmounted with a different key.
   */
  useEffect(() => {
    if (isSubmitSuccessful && !isEqual(previousValues, defaultValues)) {
      reset(defaultValues);
      setPreviousValues(defaultValues);
    }
  }, [isSubmitSuccessful, previousValues, defaultValues, reset]);

  if (defaultI18n.loading || ignoreProvisionedI18n.loading) {
    return null;
  }
  return (
    <InputEditForm<FormDataT>
      onSubmit={handleSubmit}
      submitOnChange={submitOnChange}
      formSpec={formReturn}
    />
  );
};

export default WidgetEditorForm;
